function onDOMContentLoaded(_event: Event) {
  const item = document.getElementById('header-link-shop');

  // We need optional chaining here because some templates exclude the shop menu
  item?.addEventListener('click', onClick);

  addEventListener('menu-closed', onMenuClosed);
}

function onMenuClosed(event: WindowEventMap['menu-closed']) {
  const link = document.getElementById('header-link-shop');
  if (link) {
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    const menu = event.detail?.name;
    if (menu && !['account-menu', 'shop-menu', 'upsell'].includes(menu)) {
      link.setAttribute('aria-expanded', 'false');
    }

    link.focus();
  }
}

/**
 * Handle a click on the Shop menu item in the main menu.
 *
 * If the shop menu is currently displayed, close the shop menu.
 *
 * If the shop menu is not currently displayed, open the shop menu.
 */
function onClick(_event: MouseEvent) {
  if (document.body.dataset.menuOpen === 'shop-menu') {
    dispatchEvent(new Event('menu-close-requested'));
  } else {
    type Detail = WindowEventMap['menu-open-requested']['detail'];
    dispatchEvent(new CustomEvent<Detail>('menu-open-requested', {
      detail: {
        name: 'shop-menu'
      }
    }));
  }
}

if (document.readyState === 'complete' || document.readyState === 'interactive') {
  setTimeout(onDOMContentLoaded);
} else {
  addEventListener('DOMContentLoaded', onDOMContentLoaded);
}
