/**
 * When the side menu is opened, toggle the hamburger icon in the header.
 *
 * This assumes that the document is ready because menu-opened is not fired before DOMContentLoaded.
 */
function onMenuOpened(_event: WindowEventMap['menu-opened']) {
  const hamburgerIcon = document.querySelector('hamburger-icon');
  if (hamburgerIcon) {
    hamburgerIcon.dataset.opened = 'true';
  }
}

function onMenuClosed(_event: WindowEventMap['menu-closed']) {
  const hamburgerIcon = document.querySelector('hamburger-icon');
  if (hamburgerIcon) {
    hamburgerIcon.dataset.opened = 'false';
  }
}

addEventListener('menu-closed', onMenuClosed);
addEventListener('menu-opened', onMenuOpened);
