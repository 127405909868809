import '@integrabeauty/klaviyo-types';

function onDOMContentLoaded(_event: Event) {
  const button = document.querySelector<HTMLButtonElement>('header button[data-klaviyo-id]');
  // The button is only present in certain header configuration.
  if (button) {
    button.addEventListener('click', onClick);
  }
}

function onClick(event: MouseEvent) {
  const button = <HTMLButtonElement>event.target;
  const klaviyoId = button?.dataset.klaviyoId;

  if (!klaviyoId) {
    console.warn('Klaviyo form id not set on a CTA');
    return;
  }

  if (!window.klaviyo) {
    console.warn('Klaviyo not loaded');
    return;
  }

  klaviyo.openForm(klaviyoId);
}

if (document.readyState === 'complete' || document.readyState === 'interactive') {
  setTimeout(onDOMContentLoaded);
} else {
  addEventListener('DOMContentLoaded', onDOMContentLoaded);
}
